#contact {
  padding: 40px 0 42px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-flow: column;

  h4 {
    font-size: 1.8em;
    text-align: center;
    margin: 0 0 15px; }

  .email-button {
    position: relative;
    overflow-x: hidden;
    display: block;
    text-decoration: none;
    text-align: center;
    color: $link-color;
    font-family: 'Playfair Display', serif;
    font-size: 1.6em;
    margin: 0 auto;
    padding: 14px 30px 18px;
    border: 2px solid $button-border-color;
    border-radius: 6px;

    transition: all .8s ease-in 300ms, box-shadow 0ms;
    //Box-shadow transition is so it doesn't affect focus highlight

    background-color: $bg-color;
    background-image: linear-gradient(to right, $bg-color 0%, $bg-color 24%, $selection-color 26%, $bg-color 30%, $bg-color 35%, $selection-color 40%, $bg-color 41%, $selection-color 70%, $selection-color 100%);
    background-size: 500%;

    .button-text {
      @media only screen and (min-width: $breakpoint-mobile-l) {
        position: relative;
        transform: translateX(.8em);
        display: inline-block;
        top: -.08em;
        transition: transform 600ms ease-in 300ms; } }

    .mail-icon {
      display: none;

      @media only screen and (min-width: $breakpoint-mobile-l) {
        display: inline-block;
        position: relative;
        bottom: -.1em;
        transform: translateX(100px);
        transition: transform 600ms cubic-bezier(.6,-0.28,.74,.05) 0ms, opacity 700ms ease 100ms;
        font-size: 2.4rem;
        opacity: 0; } }

    &:hover,&:focus {
      background-color: $link-hover-color;
      background-position: right center;
      color: #719ec1;
      border-radius: 50px;
      transition: all 1s ease-in 0ms;

      @media only screen and (min-width: $breakpoint-mobile-l) {
        .button-text {
          transform: translateX(-2px);
          transition: transform 600ms ease-in 750ms; }

        .mail-icon {
          transform: translateX(0);
          opacity: .4;
          transition: transform 500ms ease-in 800ms, opacity 600ms ease 1100ms; } } }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px $bg-color, 0 0 0 6px $link-hover-color;
      transition: box-shadow 0ms; } }

  .social {
    text-align: center;
    margin-top: 16px;

    a {
      font-size: 3rem;
      color: #bbb;
      text-decoration: none;

      &:first-child {
        margin-right: 8px;
        letter-spacing: -0.086em; }

      i {
        transition: transform 400ms ease-in-out; }

      &:hover,&:focus {
        color: $link-hover-color;

        i {
          transform: scale(1.1); } } } } }
