@keyframes slide-right {
  0% {
    transform: translateX(-40px);
    opacity: 0; }

  70% {
    opacity: .5; }

  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-left {
  0% {
    transform: translateX(40px);
    opacity: 0; }

  70% {
    opacity: .5; }

  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }
