section {
	margin-bottom: 80px;

	@media only screen and (min-width: $breakpoint-monitor-sm) {
		padding-top: 20px;
		margin-bottom: 140px; } }

.container {
	max-width: $container-sm;
	margin-left: auto;
	margin-right: auto;

	@media only screen and (min-width: $breakpoint-mobile-l) and (max-width: $breakpoint-monitor-sm) {
		padding-left: 20px;
		padding-right: 20px; } }
