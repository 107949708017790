#landing {
  max-width: $container-m;
  margin-left: auto;
  margin-right: auto;
  margin-top: 26px;
  margin-bottom: 38px;

  @media only screen and (min-width: $breakpoint-tablet) {
    display: flex;
    padding: 15px;
    margin: 0 auto 100px; }

  .title-container {
    display: flex;
    justify-content: center;

    @media only screen and (min-width: $breakpoint-tablet) {
      flex-grow: 9;
      align-self: center;
      justify-content: left; }

    @media only screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-monitor-sm) {
      padding-left: 25px; }

    .title {
      margin-top: 0;
      margin-bottom: 34px;

      h1 {
        animation: slide-right 600ms ease-out;
        animation-fill-mode: backwards;

        @media only screen and (min-width: $breakpoint-monitor-sm) {
          font-size: 4.4em; }

        @media only screen and (min-width: $container-m) {
          font-size: 4.5em; } }

      h2 {
        animation: slide-right 600ms ease-out 200ms;
        animation-fill-mode: backwards;

        @media only screen and (min-width: $breakpoint-monitor-sm) {
          font-size: 2.7em; }

        @media only screen and (min-width: $container-m) {
          font-size: 2.8em; } } } }

  .portrait-container {
    @media only screen and (min-width: $breakpoint-tablet) {
      flex-grow: 1; }

    .portrait-inner {
      /* This is the aspect ratio padding fix to prevent reflow on page load */
      width: 100%;
      padding-bottom: (600/400)*100%;
      position: relative;
      height: 0;
      overflow: hidden;

      img {
        display: block;
        margin: 0 auto;
        max-width: 450px;
        animation: fade-in 1000ms ease-out;

        @media only screen and (min-width: $breakpoint-tablet) {
          width: 350px; }

        @media only screen and (min-width: $breakpoint-monitor-sm) {
          width: 400px; } } } } }
