nav {
  max-width: $container-m;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.2em;
  animation: fade-in 1000ms ease-out;

  @media only screen and (min-width: $breakpoint-tablet) {
    justify-content: flex-end;
    text-align: center;
    margin: 20px auto 23px; }

  a {
    text-decoration: none;
    color: $lighter-text-color;

    @media only screen and (min-width: $breakpoint-tablet) {
      width: 100px;
      transform-origin: top center;
      transition: transform .2s ease-in-out;

      &:hover, &:focus {
          transform: scale(1.2); } } } }
