#testimonial {
  text-align: center;
  margin-top: 120px;
  margin-bottom: 100px;

  @media only screen and (min-width: $breakpoint-tablet) {
    display: flex;
    text-align: left;
    margin-top: 0;
    margin-bottom: 140px; }

  .name {
    color: $lighter-text-color;

    @media only screen and (min-width: $breakpoint-tablet) {
      margin-bottom: 0; } }

  .position {
    display: block;
    font-size: .9em;
    margin-left: 9px;

    @media only screen and (min-width: $breakpoint-tablet) {
      display: inline;
      margin-left: 0;
      font-size: .8em;
      font-style: italic; } }

  picture {
    width: 130px;
    flex-shrink: 0; //This is needed or firefox makes it too small
    margin: 0 auto;
    display: block;

    @media only screen and (min-width: $breakpoint-tablet) {
      order: -1;
      align-self: flex-start; } }

  .quote-container {
    margin: 5px;

    @media only screen and (min-width: $breakpoint-tablet) {
      align-self: center;
      margin-left: 30px;
      margin-right: 0; }

    .quote {
      quotes: "“" "”";

      @media only screen and (min-width: $breakpoint-tablet) {
        margin-bottom: 4px; }

      &::before {
        content: open-quote;
        display: inline;
        height: 0;
        line-height: 0;
        left: -10px;
        position: relative;
        top: 20px;
        color: #ccc;
        font-size: 3em; }

      &::after {
        content: close-quote;
        display: inline;
        height: 0;
        line-height: 0;
        left: 6px;
        position: relative;
        top: 23px;
        color: #ccc;
        font-size: 3em; } } } }

