#about {
  animation: fade-in 1000ms ease-out;

  a {
    color: $link-color;
    background: linear-gradient(to bottom, $link-hover-bg-color 0%, $link-hover-bg-color 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 4px 3px;
    text-decoration: none;
    transition: background-size .5s;

    &:hover, &:focus {
      background-size: 4px 50px; } } }
