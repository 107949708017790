$total-width: 201%;
$slide-width: 50%;

#work {
  width: 100%;
  overflow-x: hidden;

  //Prevents outline on whole section when clicking back and forth
  ////#work is focused on for screenreaders/tabbing
  &:focus {
    box-shadow: none; }

  .hidden {
      display: none !important; }

  h4 {
    margin-top: 0;
    margin-bottom: 0; }

  img {
    border: 1px solid #000; }

  .slides {
    width: $total-width;
    position: relative;
    left: 0;
    transition: left .5s ease-in-out;

    &.project-open {
      left: -101%; } }

  .thumb-slide {
    width: $slide-width;
    float: left;
    display: flex;
    flex-flow: column;
    align-items: center;

    @media only screen and (min-width: $breakpoint-monitor-sm) {
      flex-flow: row;
      justify-content: center; } }


  .thumb {
    margin-bottom: 20px;
    max-width: 600px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: $text-color;
    flex: 1;

    @media only screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-monitor-sm) {
      margin-left: 5px;
      margin-right: 5px; }

    @media only screen and (min-width: $breakpoint-monitor-sm) {
      margin-left: 15px;
      margin-right: 15px; }

    h4 {
      margin: 0 0 -17px;
      text-align: left;
      font-size: 1.3rem; }

    img {
      transition: filter .5s ease-in-out, opacity .5s ease-in-out;
      @include grayscale(100%);
      opacity: .4;
      margin: 15px 0;
      width: 98%; }

    &:hover, &:focus {

      img {
        @include grayscale(0%);
        opacity: 1; } } }

  .project-slide {
    width: $slide-width;
    margin-bottom: 20px;
    float: right;


    .project {
      max-width: $container-m;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      .row {
        display: block;

        @media only screen and (min-width: $breakpoint-monitor-sm) {
          display: flex; }

        .img-container, .info-container {
          padding: 15px;

          @media only screen and (min-width: $breakpoint-monitor-sm) {
            flex: 50%; } }

        .img-container {
          max-width: 450px;
          margin: 0 auto;
          text-align: center;

          @media only screen and (min-width: $breakpoint-tablet) {
            max-width: none; }

          @media only screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-monitor-sm) {
            flex: 40%; }

          img {
            max-width: 600px; } }

        .info-container {
          @media only screen and (min-width: $breakpoint-tablet)  and (max-width: $breakpoint-monitor-sm) {
            flex: 60%; } }

        .info-container:not(.first-info) {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: flex-start; } }

      h4 {
        display: block;
        font-size: 1.6rem;
        text-align: center;

        @media only screen and (min-width: $breakpoint-mobile-l) {
          display: inline;
          font-size: 2rem;
          margin-left: 5px;
          text-align: left; } }

      ul {
        margin-top: 0.9em;
        margin-bottom: 0.9em; }

      .info-title {
        margin-top: 0;
        margin-bottom: 0.3em;
        font-weight: 600;
        font-size: 1rem; }

      .info {
        @media only screen and (min-width: $breakpoint-tablet) {
          margin-left: 1em; } }

      .return-arrow {
        color: $link-color;
        font-size: 1.2rem;
        display: inline;
        position: relative;
        top: -6px;
        transition: color 200ms ease-in-out;

        i {
          border: 2px solid $link-color;
          font-size: 0.8rem;
          padding: 5px;
          border-radius: 50%;
          transition: border-color 200ms ease-in-out; }

        .go-back {
          position: relative;
          left: 6px;
          top: 2px;
          padding-right: 6px; }

        &:hover,&:focus {
          color: $link-hover-color;

          i {
            border-color: $link-hover-color; } } } } } }


